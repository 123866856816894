import useMediaQuery from '@mui/material/useMediaQuery';

const useGetPagePadding = () => {
  const lg = useMediaQuery('(min-width:1540px)');
  const md = useMediaQuery('(min-width:1024px)');
  const sm = useMediaQuery('(min-width:640px)');

  const getPagePadding = () => {
    if (lg) return 80;
    if (md) return 40;
    if (sm) return 24;

    return 24;
  };

  return { getPagePadding };
};

export default useGetPagePadding;
