export const SUBMITTED_TEXT = 'submitted';
export const REORDER_NO_ACCESS_TEXT = '🚫 you do not have permission';
export const REPLENISHMENT_NO_ACCESS_TEXT = '🚫 you do not have permission';
export const BUYING_NO_ACCESS_TEXT = '🚫 you do not have permission';
export const NO_ACCESS_TEXT = '🚫 you do not have permission';
export const REBALANCING_NO_ACCESS_TEXT = '🚫 you do not have permission';
export const VIEW_SCOPE_TEXT = 'View scope';
export const EXPORT_TOOLTIP_TEXT = 'Export';
export const SHARE_TOOLTIP_TEXT = 'Share';
export const COPIED_URL_TOOLTIP_TEXT = 'Link copied to your clipboard!';
