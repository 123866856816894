// ----------------------------------------------------------------------

export function remToPx(value: string): number {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number): string {
  return `${value / 16}rem`;
}

export function pxToEm(value: number): string {
  return `${value / 16}em`;
}
