import { AuthGuard, REORDER_PATH_APP } from '@autone/ui';
import { Redirect } from 'react-router-dom';

import { PageLayout } from '../layout';

const RedirectRoute = {
  guard: AuthGuard,
  layout: PageLayout,
  routes: [
    {
      exact: true,
      path: '/',
      component: () => <Redirect to={REORDER_PATH_APP.overview} />,
    },
    {
      component: () => <Redirect to={REORDER_PATH_APP.overview} />,
    },
  ],
};

export default RedirectRoute;
