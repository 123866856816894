import { coreApi, priceTypeReducer, userApi, userReducer } from '@autone/ui';
import { configureStore, Dispatch } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { reorderApi } from './apis/reorder';

const store = configureStore({
  reducer: {
    user: userReducer,
    [userApi.reducerPath]: userApi.reducer,
    [coreApi.reducerPath]: coreApi.reducer,
    [reorderApi.reducerPath]: reorderApi.reducer,
    priceType: priceTypeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(coreApi.middleware)
      .concat(reorderApi.middleware),
});
export default store;

// below code taken from https://redux.js.org/usage/usage-with-typescript
// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => AppDispatch;

type RootState = ReturnType<typeof store.getState>;
// TODO: this is a workaround to fix a type error following versions fix
export type AppDispatch = Dispatch<any>;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
