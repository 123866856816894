import { isNumber, replace } from 'lodash';
import numeral from 'numeral';
// ----------------------------------------------------------------------

const getDecimalPlaces = (num: number) => {
  if (num < 1000) return 0;
  if (num >= 1000 && num < 10000) return 2;
  return 1;
};

export const fReadableCurrency = (
  number: number | undefined | null,
  currencyISO?: string,
  locale?: string,
) => {
  if (!isNumber(number)) return 'N/A';

  const decimals = getDecimalPlaces(number);

  const currencyFormatted = new Intl.NumberFormat(locale || 'en-US', {
    style: 'currency',
    notation: 'compact',
    currency: currencyISO || 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals,
  });

  return `${currencyFormatted.format(number)}`;
};

export function fCurrency(
  number: number | undefined | null,
  currencyISO?: string | undefined,
  fractionalDigits?: number,
) {
  if (number == null) {
    return 'N/A';
  }

  const currencyFormatted = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyISO || 'EUR',
    minimumFractionDigits: fractionalDigits || 0,
    maximumFractionDigits: fractionalDigits || 0,
  });

  return currencyFormatted.format(parseInt(number.toString(), 10));
}

// TODO: remove this function when old code has been removed
export function fCurrencyThousand(
  number: number | undefined | null,
  currencyISO: string | undefined,
) {
  const fractionalDigits =
    number != null && isNumber(number) && Math.abs(number) < 10000 ? 1 : 0;

  const currencyFormatted = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyISO || 'EUR',
    minimumFractionDigits: fractionalDigits,
    maximumFractionDigits: fractionalDigits,
  });

  return isNumber(number)
    ? `${currencyFormatted.format(number / 1000)} K`
    : 'N/A';
}

export function fPercent(number: number) {
  return numeral(number).format('0,0%');
}

export function fPercentWithThreshold(
  number: number,
  threshold: { value: number; label: string },
) {
  if (number < threshold.value) {
    return threshold.label;
  }

  return fPercent(number);
}

export function fPercentTwoDp(number: number) {
  return numeral(number).format('0.00%');
}

export function fNumber(number?: number | null, format?: string) {
  if (number == null) {
    return 'N/A';
  }

  return numeral(number).format(format);
}

export function fShortenNumber(number: number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number: number) {
  return numeral(number).format('0.0 b');
}

export function fNumberWithCommas(number?: number | null) {
  if (number == null) {
    return 'N/A';
  }

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function fRoundedNumberWithCommas(number?: number | null) {
  if (number == null) {
    return 'N/A';
  }

  return Math.round(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function fPercentage(number: number) {
  return numeral(number).format('0%');
}

export function fNumberNull(number?: number | null) {
  if (number == null || isNaN(number)) {
    return 'N/A';
  }

  return number;
}

export function fNullData(data: null): 'N/A';

export function fNullData(data: '-'): 'N/A';

export function fNullData<Data>(data: Data): Data;
export function fNullData<Data>(data: Data | null | '-'): Data | 'N/A' {
  return data == null || data === '-' ? 'N/A' : (data as Data);
}

export function fRound(number: number, decimalPlaces: number = 0) {
  const format = `0.[${'0'.repeat(decimalPlaces)}]`;
  return numeral(number).format(format);
}
