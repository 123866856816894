import { UPDATE_CONFIG } from '@autone/ui';
import { useDispatch } from 'react-redux';

import { getUserConfig } from '../utils/apis';

const useUserActions = () => {
  const dispatch = useDispatch();

  const fetchUserData = async () => {
    const { data } = (await getUserConfig()) || {};

    dispatch(UPDATE_CONFIG(data));
  };

  return {
    fetchUserData,
  };
};

export default useUserActions;
