import { AUTONE_LANGUAGE_STORAGE_KEY } from '@autone/utils';
import dayjs from 'dayjs';
import i18n from 'i18next';
import LanguageDetector, {
  DetectorOptions,
} from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import Backend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';

import 'moment/dist/locale/fr';
import 'moment/dist/locale/it';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import { addFormatters } from './formatters';

const detectionOptions: DetectorOptions = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage'],
  lookupLocalStorage: AUTONE_LANGUAGE_STORAGE_KEY,
};

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem(AUTONE_LANGUAGE_STORAGE_KEY) || 'en',
    detection: detectionOptions,
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'en',
    ns: ['common'],
    returnEmptyString: false,
    backend: {
      backends: [
        Backend,
        resourcesToBackend((language: string, namespace: string) => {
          if (language === 'en-US') {
            return import(`../../locales/en/${namespace}.json`);
          }
          // @ts-ignore
          return import(`../../locales/${language}/${namespace}.json`);
        }),
      ],
      backendOptions: [
        {
          // AWS Bucket link
          loadPath: `${
            process.env.PUBLIC_URL ?? ``
          }/locales/{{lng}}/{{ns}}.json`,
        },
      ],
    },
  });

addFormatters(i18n);
i18n.on('languageChanged', (lng) => {
  switch (lng) {
    case 'fr':
    case 'it':
    case 'en': {
      moment.locale(lng);
      dayjs.locale(lng);
      return;
    }
    default: {
      moment.locale(lng);
      dayjs.locale(lng);
      return;
    }
  }
});

export default i18n;
