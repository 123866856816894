import { AutoneTranslation, i18nAutone } from '@autone/translations';
import {
  AppShell,
  ErrorFallback,
  useAppSettings,
  usePriceTypeOptions,
} from '@autone/ui';
import {
  REORDER_ADMIN,
  REORDER_READONLY,
  REORDER_STANDARD,
} from '@autone/utils';
import { ErrorBoundary } from 'react-error-boundary';

import { APP_NAME } from './constant';
import useUser from './hooks/useUser';
import store from './redux/store';
import AppRoutes from './routes/app.routes';
import ErrorRoute from './routes/error.route';
import LoadingRoute from './routes/loading.route';
import NewReorderRoute from './routes/new-reorder.route';
import RedirectRoute from './routes/redirect.route';

function App() {
  const routes = [
    NewReorderRoute,
    LoadingRoute,
    ErrorRoute,
    AppRoutes,
    RedirectRoute,
  ];

  // set up app settings - intercom, sentry, mixpanel & fullview
  useAppSettings(APP_NAME);

  // push the price type options and defaults into redux
  usePriceTypeOptions();

  // get user data
  useUser();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AutoneTranslation namespace={APP_NAME} i18n={i18nAutone}>
        <AppShell
          reduxStore={store}
          routes={routes}
          permissionGroups={[REORDER_ADMIN, REORDER_READONLY, REORDER_STANDARD]}
        />
      </AutoneTranslation>
    </ErrorBoundary>
  );
}

export default App;
