import { useEffect } from 'react';

import useUserActions from './useUserActions';

const useUser = () => {
  const { fetchUserData } = useUserActions();

  // when first loaded - fetch the user data
  useEffect(() => {
    fetchUserData();
  }, []);

  return {};
};

export default useUser;
