import { AuthGuard, REORDER_PATH_APP } from '@autone/ui';
import { lazy } from 'react';

import { DashboardLayout } from '../layout';

// ---------------------- App Routes --------------- //
// AuthGuard checks if user has currently logged in or not when a user accesses any given page
const AppRoutes = {
  guard: AuthGuard,
  layout: DashboardLayout,
  path: REORDER_PATH_APP.root,
  routes: [
    // if a user hits the homepage ('/') then redirect to REORDER_PATH_APP.app
    {
      exact: true,
      path: REORDER_PATH_APP.overview,
      component: lazy(() => import('../views/Overview')),
    },
    {
      exact: true,
      path: REORDER_PATH_APP.reorder,
      component: lazy(() => import('../views/EditReorder')),
    },
    {
      exact: true,
      path: REORDER_PATH_APP.summary,
      component: lazy(() => import('../views/BatchSummary')),
    },
    {
      exact: true,
      path: REORDER_PATH_APP.confirmedReorder,
      component: lazy(() => import('../views/SubmissionConfirmation')),
    },
  ],
};

export default AppRoutes;
