export const BUYING_ADMIN = 'BUYING_ADMIN';
export const BUYING_STANDARD = 'BUYING_STANDARD';
export const BUYING_READONLY = 'BUYING_READONLY';
export const INSIGHTS_READONLY = 'INSIGHTS_READONLY';
export const PARAMETERS_ADMIN = 'PARAMETERS_ADMIN';
export const PARAMETERS_READONLY = 'PARAMETERS_READONLY';
export const REORDER_ADMIN = 'REORDER_ADMIN';
export const REORDER_STANDARD = 'REORDER_STANDARD';
export const REORDER_READONLY = 'REORDER_READONLY';
export const REPLENISHMENT_ADMIN = 'REPLENISHMENT_ADMIN';
export const REPLENISHMENT_STANDARD = 'REPLENISHMENT_STANDARD';
export const REPLENISHMENT_READONLY = 'REPLENISHMENT_READONLY';
export const CORE_STANDARD = 'CORE_STANDARD';
export const CORE_ADMIN = 'CORE_ADMIN';
export const REBALANCING_ADMIN = 'REBALANCING_ADMIN';
export const REBALANCING_STANDARD = 'REBALANCING_STANDARD';
export const REBALANCING_READONLY = 'REBALANCING_READONLY';
export const ASSORTMENT_ADMIN = 'ASSORTMENT_ADMIN';
export const ASSORTMENT_STANDARD = 'ASSORTMENT_STANDARD';
export const ASSORTMENT_READONLY = 'ASSORTMENT_READONLY';
