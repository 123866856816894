import { useAutoneTranslation } from '@autone/translations';
import { AuthGuard, ErrorView, REORDER_PATH_APP } from '@autone/ui';

import { APP_NAME } from '../constant';
import { PageLayout } from '../layout';
import { getErrors } from '../utils/helpers/getErrors';

const ErrorComponent = () => {
  const { t, common } = useAutoneTranslation<typeof APP_NAME>();

  return (
    <ErrorView
      module="reorder"
      errorCodes={getErrors({ t })}
      text={{
        header: common('error-page.header'),
        description: common('error-page.description'),
        contactUs: common('error-page.contact-us'),
      }}
    />
  );
};

const ErrorRoute = {
  guard: AuthGuard,
  layout: PageLayout,
  path: REORDER_PATH_APP.error,
  routes: [
    {
      exact: true,
      path: REORDER_PATH_APP.error,
      component: ErrorComponent,
    },
    {
      exact: true,
      path: REORDER_PATH_APP.errorCode,
      component: ErrorComponent,
    },
  ],
};

export default ErrorRoute;
