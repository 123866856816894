import { snakeCase } from 'lodash';

export function titleCase(str: string | null): string {
  if (str === undefined || str === null) {
    return '';
  }

  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );
}

export function capitalizeFirstLetter(str?: string | null): string {
  if (str === undefined || str === null) {
    return '';
  }

  const strLower = str.toLowerCase();

  return strLower.charAt(0).toUpperCase() + strLower.slice(1);
}

export function capitalizeWords(str?: string | null): string {
  if (str == null) {
    return '';
  }

  return str
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
}

export function snakeCaseToProper(str: string | null): string {
  if (str === undefined || str === null) {
    return '';
  }

  return str.replace(/^_*(.)|_+(.)/g, (str, c, d) =>
    c ? c.toUpperCase() : ` ${d.toLowerCase()}`,
  );
}

export function fNullText(str?: string | null): string {
  if (str === undefined || str === null) {
    return 'nm';
  }

  return str;
}

export const fRemoveEndingS = (str: string): string =>
  str?.endsWith('s') ? str?.slice(0, -1) : str;

export const convertNullStringToNull = (str: string): string | null => {
  if (str === 'null') {
    return null;
  }
  return str;
};

export const removeTextAfterFirstUnderScore = (str: string): string => {
  if (str.includes('_')) {
    return str.split('_')[0];
  } else {
    return str;
  }
};

export const screamingCase = (str: string): string => {
  return snakeCase(str).toUpperCase();
};

export const capitaliseAndCommaSeparate = (array: string[]): string => {
  return array.map((str) => capitalizeFirstLetter(str)).join(', ');
};
